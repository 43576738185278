import React, { useRef } from 'react'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AllowLeftIcon from '~/components/molecules/AllowLeftIcon'
import {
    container,
    grey,
    item,
    slider,
    subtitle,
    title,
    custom_dots, custom_dot, pc_hide, slider_wrapper, custom_dot__left, custom_dot__right, sp_hide
} from './styles'
import { breakpoints } from '~/utils/styles'
import AllowRightIcon from '~/components/molecules/AllowRightIcon'


const Voice = (): JSX.Element => {
    const ref = useRef<Slider | null>(null)

    const CustomDots = (props) => {
        return (
            <div css={custom_dots}>
                <AllowLeftIcon css={[custom_dot, pc_hide]} onClick={handlePrevContent} />
                <ul>{props}</ul>
                <AllowRightIcon css={[custom_dot, pc_hide]} onClick={handleNextContent} />
            </div>
        )
    }

    const handleNextContent = () => {
        ref?.current?.slickNext()
    }

    const handlePrevContent = () => {
        ref?.current?.slickPrev()
    }

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendDots: CustomDots,
        responsive: [
            {
                breakpoint: breakpoints.tablet,
                settings: {
                    autoplay: true,
                    autoplaySpeed: 4000,
                    pauseOnHover: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true
                }
            },
            {
                breakpoint: breakpoints.pc,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    }

    return (
        <div css={grey}>
            <Container css={container}>
                <Typography css={subtitle} color='textSecondary'>Voice</Typography>
                <Typography css={title} color='textPrimary'>Onsen*入浴剤を導入した店舗様の声</Typography>
                <div css={slider_wrapper}>
                    <Slider css={slider} {...settings} ref={ref}>
                        <li>
                            <article css={item}>
                                <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">本日も開催中♨️<br/>四日目『ヒノキ香るonsen』の露天替わり湯。<br/>金曜日24日が最終日です。<br/>ハッシュタグ『<a
                                    href="https://twitter.com/hashtag/%E6%9C%9D%E6%97%A5%E6%B8%A9%E6%B3%89?src=hash&amp;ref_src=twsrc%5Etfw">#朝日温泉</a>』と『<a
                                    href="https://twitter.com/hashtag/%E3%81%8A%E3%81%86%E3%81%A1%E3%81%A7onsen?src=hash&amp;ref_src=twsrc%5Etfw">#おうちでonsen</a>』をつけた投稿をツイッターでつぶやいて頂くと「気軽におうちで極楽」『onsenの素』プレゼント！残りわずかです🎁 <a
                                    href="https://t.co/XJm0ZxOnEh">pic.twitter.com/XJm0ZxOnEh</a>
                                </p>&mdash; 銭湯ボーーイ@朝日温泉すたっふ (@latinsento) <a
                                    href="https://twitter.com/latinsento/status/1440899811385888771?ref_src=twsrc%5Etfw">September
                                    23, 2021</a>
                                </blockquote>
                            </article>
                        </li>
                        <li>
                            <article css={item}>
                                <blockquote className="twitter-tweet">
                                    <p lang="ja" dir="ltr">名古屋の白山温泉♨️です<br/>日曜は朝8時から昼12時まで、昼3時から夜12時まで営業しています💪<br/><br/>今日は、Onsenさんの「ヒノキの香り」やっています<br/>ヒノキも浮かべていますよ🤗<br/><br/>大雨ですね<br/>こんな日は近くの銭湯でゆっくりされるのはいかがですか😁<a
                                    href="https://twitter.com/hashtag/%E5%90%8D%E5%8F%A4%E5%B1%8B?src=hash&amp;ref_src=twsrc%5Etfw">#名古屋</a>
                                    <a href="https://twitter.com/hashtag/%E9%8A%AD%E6%B9%AF?src=hash&amp;ref_src=twsrc%5Etfw">#銭湯</a>
                                    <a href="https://twitter.com/hashtag/%E3%82%B5%E3%82%A6%E3%83%8A?src=hash&amp;ref_src=twsrc%5Etfw">#サウナ</a>
                                    <a href="https://twitter.com/hashtag/onsen?src=hash&amp;ref_src=twsrc%5Etfw">#onsen</a>
                                    <a href="https://twitter.com/hashtag/%E3%83%92%E3%83%8E%E3%82%AD%E3%81%AE%E9%A6%99%E3%82%8A?src=hash&amp;ref_src=twsrc%5Etfw">#ヒノキの香り</a>
                                    <a href="https://t.co/B93nGBaFp6">pic.twitter.com/B93nGBaFp6</a></p>&mdash; 白山温泉
                                    2種類のサウナと8種類のお風呂 110周年(創業1911年) (@hakusanonsen758) <a
                                        href="https://twitter.com/hakusanonsen758/status/1441899164720197632?ref_src=twsrc%5Etfw">September
                                        25, 2021</a>
                                </blockquote>
                            </article>
                        </li>
                        <li>
                            <article css={item}>
                                <blockquote className="twitter-tweet">
                                    <p lang="ja" dir="ltr">こんにちは！<br/>みやの湯オープンしました。<br/><br/>Onsen*
                                    ヒノキ、当湯は本日ファイナルです😭<br/>というわけで、源泉なみに濃ゆい液に浸しておいたヒノキの切株たちをサウナ室に入れてみたよ✨<br/><br/>プレゼント入浴剤もまだありますので是非ご来店くださいませ😊 <a
                                        href="https://t.co/dKddzI6S4t">pic.twitter.com/dKddzI6S4t</a></p>&mdash; みやの湯
                                    (大阪府門真市) (@miyanoyu_kadoma) <a
                                        href="https://twitter.com/miyanoyu_kadoma/status/1442355245724569603?ref_src=twsrc%5Etfw">September
                                        27, 2021</a>
                                </blockquote>
                            </article>
                        </li>
                        <li>
                            <article css={item}>
                                <blockquote className="twitter-tweet">
                                    <p lang="ja" dir="ltr">営業開始♨️♨️<br/>今日はOnsen（<a
                                    href="https://twitter.com/onsen_cure?ref_src=twsrc%5Etfw">@onsen_cure</a>）さんの入浴剤でーす！！関東初、かつ関東では千代の湯だけです！！沢山の人に入って欲しいので主浴槽の方に入れましたが、今日はなんと主浴槽の方にもトゴール入れてるのでトゴール✕Onsenですよ〜☺️☺️<br/>是非是非体を休めてください🥰🥰<a
                                    href="https://twitter.com/hashtag/%E9%8A%AD%E6%B9%AF?src=hash&amp;ref_src=twsrc%5Etfw">#銭湯</a><a
                                    href="https://twitter.com/hashtag/%E8%8D%92%E5%B7%9D%E5%8C%BA?src=hash&amp;ref_src=twsrc%5Etfw">#荒川区</a><a
                                    href="https://twitter.com/hashtag/Onsen?src=hash&amp;ref_src=twsrc%5Etfw">#Onsen</a>
                                    <a href="https://t.co/rqn0cXyJvG">pic.twitter.com/rqn0cXyJvG</a></p>&mdash; 千代の湯￤荒川
                                    (@chiyonoyu1010) <a
                                        href="https://twitter.com/chiyonoyu1010/status/1441643771574161409?ref_src=twsrc%5Etfw">September
                                        25, 2021</a>
                                </blockquote>
                            </article>
                        </li>
                    </Slider>
                    {ref && (
                        <>
                            <AllowLeftIcon css={[custom_dot, custom_dot__left, sp_hide]} onClick={handlePrevContent} />
                            <AllowRightIcon css={[custom_dot, custom_dot__right, sp_hide]}
                                            onClick={handleNextContent} />
                        </>
                    )}
                </div>
            </Container>
        </div>
    )
}

export default Voice