import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const sp_hide = css`
	display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
	}
`

export const no_margin = css`
	margin-top: 0;
`

export const container = css`
    padding-top: 40px;
    padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
`

export const list = css`
    display: grid;
    gap: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		gap: 90px;
	}
`

export const article = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		justify-content: space-between;
		align-items: start;
	}
`

export const flex_reverse = css`
	flex-direction: row-reverse;
`

export const article__thumbnail = css`
    overflow: hidden;
	border-radius: 12px;
	height: 223px;
	background-size: cover;
	@media (min-width: ${breakpoints.tablet}px) {
		height: 427px;
		width: calc(58% - 30px);
	}
`

export const article__contents = css`
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(42% - 30px);
	}
`

export const article__title = css`
	margin-top: 10px;
	margin-bottom: 10px;
    font-size: 2.4rem;
	@media (min-width: ${breakpoints.pc}px) {
		margin-bottom: 20px;
		font-size: 32px;
	}
`

export const article__subtitle = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const article__description = css`
    margin-top: 20px;
	font-weight: 500;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const components_figure = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(58% - 30px);
		align-self: start;
		margin-top: 0;
	}
`

export const fragrance_list_container = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
    
    li {
        :first-of-type {
	        border-radius: 8px 8px 0 0;
        }
        :last-of-type {
	        border-radius: 0 0 8px 8px;
	        margin-bottom: 0;
        }
    }
`

export const fragrance_list = css`
	background-color: ${palette.grey['500']};
	margin-bottom: 1px;
	padding: 13px 15px;
	display: flex;
	justify-content: space-between;
`


export const fragrance_list_title = css`
	flex: none;
	margin-right: 20px;
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 1.6rem;
	}
`

export const fragrance_list_description = css`
	font-weight: 500;
	align-self: center;
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 1.6rem;
		line-height: 160%;
	}
`

export const article_primary_text = css`
	font-weight: bold;
	color: ${palette.text.primary};
`