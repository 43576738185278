import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const pc_hide = css`
	display: block;
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const sp_hide = css`
	display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
	}
`

export const grey = css`
	background-color: ${palette.grey['500']};
`

export const container = css`
	padding-top: 40px;
	padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 56px;
		padding-bottom: 56px;
	}
`

export const title = css`
	margin-top: 10px;
	font-size: 3.2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		text-align: center;
	}
`
export const subtitle = css`
	font-size: 1.6rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2rem;
		text-align: center;
	}
`

export const slider = css`
	margin-top: 20px;
	margin-left: -20px;
	margin-right: -20px;
	overflow: hidden;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 60px;
		margin-left: 0;
		margin-right: 0;
	}

	.slick-dots li button:before {
		font-size: 1.6rem;
	}

	.slick-dots li.slick-active button:before {
		color: ${palette.primary.light};
		opacity: 1;
	}
`

export const item = css`
	overflow: hidden;
	padding: 0 8px;
	position: relative;
	@media (min-width: ${breakpoints.tablet}px) {
		min-width: auto;
		padding: 0 10px;
	}

	iframe {
		width: 100% !important;
		max-width: 100% !important;
	}
`

export const slider_wrapper = css`
	position: relative;
`

export const custom_dots = css`
	margin-top: 34px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	bottom: 0;
	padding: 0 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 30px;
		justify-content: center;
	}
`

export const custom_dot = css`
	cursor: pointer;

	:after {
		font-size: 2rem;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		position: absolute;
		top: calc(50% - 0.5em);
	}
`

export const custom_dot__left = css`
	left: -36px;
`
export const custom_dot__right = css`
	right: -31px;
`