import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const card = css`
	padding: 20px;
	background-color: ${palette.common.white};
	border-radius: 12px;
`

export const header = css`
	display: flex;
	align-items: center;

	> * + * {
		margin-left: 12px;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		> * + * {
			margin-left: 26px;
		}
	}
`

export const title = css`
	font-size: 2rem;
    flex: 1;
`

export const horizon = css`
	margin-top: 20px;
`

export const body = css`
	margin-top: 20px;
	font-weight: 500;
	font-size: 1.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`

export const popup = css`
	@media (min-width: ${breakpoints.tablet}px) {
		width: 48px;
		height: 48px;
		font-size: 2rem;
	}
`
