import { css } from '@emotion/core'

const medium = css`
    width: 40px;
    height: 40px;
`

const large = css`
	width: 80px;
	height: 80px;
`

export const sizeTheme = {
    medium,
    large
}