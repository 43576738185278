import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const pc_hide = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const title = css`
	margin-top: 10px;
	font-size: 3.2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		text-align: center;
	}
`
export const subtitle = css`
	font-size: 1.6rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2rem;
		text-align: center;
	}
`

export const container = css`
	padding-top: 40px;
	padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 56px;
		padding-bottom: 80px;
	}
`

export const facilities = css`
	margin-top: 20px;
	display: grid;
	gap: 60px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 60px;
		gap: 47px;
	}
`

export const cards = css`
	margin-top: 20px;
	display: grid;
	gap: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
`

export const horizon = css`
	margin-top: 10px;
`

export const prefecture = css`
	font-size: 2rem;
	font-weight: 500;
`