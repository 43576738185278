import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const card = css`
	overflow: hidden;
	border-radius: 12px;
`

export const thumbnail = css`
	height: 223px;
	@media (min-width: ${breakpoints.tablet}px) {
		height: 427px;
	}
`

export const body = css`
	padding: 20px;
	background-color: ${palette.grey['500']};
`

export const description = css`
	font-weight: 500;
	margin-top: 10px;
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 1.6rem;
	}
`