import React, { ComponentPropsWithRef } from 'react'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import BackgroundImage from 'gatsby-background-image'

import {
    article,
    container,
    list,
    article__description,
    article__title,
    article__subtitle,
    article__contents,
    article__thumbnail,
    effects_tags,
    effects_tag,
    detail_list_container,
    detail_list,
    detail_list_end,
    detail_list_first,
    detail_list_title,
    detail_list_description,
    detail_list_text,
    detail_list_info_text
} from './styles'
import { graphql, useStaticQuery } from 'gatsby'
import Tag from '~/components/atoms/Tag'

interface DailymineralBodyProps extends ComponentPropsWithRef<'div'> {
    effects: {
        html: string
    }[]
    details: {
        title: string
        description: string
    }[]
}

const DailymineralBody = ({ effects, details, ...props }: DailymineralBodyProps): JSX.Element => {
    const { family } = useStaticQuery<GatsbyTypes.DailymineralBodyQuery>(graphql`
        query DailymineralBody {
            family: file(relativePath: {eq: "family.jpg"}) {
			    childImageSharp {
			        fluid(maxWidth: 800) {
			            ...GatsbyImageSharpFluid_withWebp
			        }
			    }
		    }
        }
    `)
    return (
        <Container css={container} {...props}>
            <ul css={list}>
                <li>
                    <article css={article}>
                        <div css={article__contents}>
                            <Typography css={article__subtitle}>弱アルカリ性</Typography>
                            <Typography variant='h4' color='textPrimary' css={article__title}>家族に優しいお湯。</Typography>
                            <Typography variant='body1' css={article__description}>
                                「薬用入浴剤 デイリーミネラル」の湯質はおだやかな弱アルカリ性。赤ちゃんからご高齢の方までお使いいただけます。<br /><br />
                                洗髪や洗顔にもお使いいただける成分なので、湯船に浸かりゆっくり顔をすすぐのもお勧めです。お肌の角質層へしっとりと浸透するミネラルをご体験ください。（最後のすすぎは清水をお使い下さい。）
                            </Typography>
                        </div>
                        <BackgroundImage fluid={family.childImageSharp.fluid} css={article__thumbnail} />
                    </article>
                </li>
                <li>
                    <article css={article}>
                        <div css={article__contents}>
                            <Typography css={article__subtitle}>効能</Typography>
                            <Typography variant='h4' color='textPrimary' css={article__title}>悩める諸症状にも。</Typography>
                            <Typography variant='body1' css={article__description}>
                                「薬用入浴剤 デイリーミネラル」は医薬部外品の入浴剤です。有効成分の働きで温浴効果及び清浄効果を高め、複数の諸症状に対する効能が認められています。<br /><br />
                                慢性的な肩こりや疲労、また冷え症やお肌の悩みがある方の日常的な使用にもおすすめです。
                            </Typography>
                        </div>
                        <ul css={effects_tags}>
                            {effects.map((v, i) => (
                                <li key={`${v.html}-${i}`}>
                                    <Tag css={effects_tag}>{v.html}</Tag>
                                </li>
                            ))}
                        </ul>
                    </article>
                </li>
                <li>
                    <article css={article}>
                        <div css={article__contents}>
                            <Typography variant='h4' color='textPrimary' css={article__title}>詳細情報・成分</Typography>
                        </div>
                        <ul css={detail_list_container}>
                            {details.map((detail, index) => (
                                <li css={[detail_list, index === 0 && detail_list_first, index === details.length - 1 && detail_list_end]}
                                    key={`${detail.title}-${index}`}>
                                    <Typography css={detail_list_title} variant='body2'
                                                color='textPrimary'>{detail.title}</Typography>
                                    <div css={detail_list_description}>
                                        <Typography variant='subtitle2'
                                                    css={detail_list_text}>{detail.description}</Typography>
                                        {detail.title === '成分' && (
                                            <Typography color='textSecondary'
                                                        css={detail_list_info_text}>＊は「有効成分」、無表示は「その他の成分」</Typography>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </article>
                </li>
            </ul>
        </Container>
    )
}

export default DailymineralBody