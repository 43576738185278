import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	overflow: hidden;
`

export const dotsContainer = css`
	bottom: 12px;
	
	// アクティブなdot
	.slick-active > span {
		color: ${palette.common.white};
		opacity: 1;
	}
`

export const dot = css`
	color: ${palette.text.secondary};
	opacity: 0.2;
`