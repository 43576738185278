import React, { FC } from 'react'
import Typography from '~/components/atoms/Typography'
import {
	container,
	highlight_text,
	label_container,
	max_bar,
	empty_bar,
	min_bar,
	bar_container,
	middle_bar,
	note_container, title, label_sub_text, note_description,
	amount_text, catch_label_container, catch_amount_text,
} from './styles'

const EffectsFigure: FC = ({ ...props }) => {
	return (
		<div css={container} {...props}>
			<Typography color='textPrimary' variant='h5' css={title}>弱アルカリ性の湯心地</Typography>
			<div css={catch_label_container}>
				<Typography>
					デイリーミネラル <span css={label_sub_text}>| 弱アルカリ性</span>
				</Typography>
				<Typography css={[highlight_text, catch_amount_text]}>pH9.6</Typography>
			</div>
			<div css={max_bar}/>

			<div css={label_container}>
				<Typography>
					純水 <span css={label_sub_text}>| 中性</span>
				</Typography>
				<Typography variant='h6' css={amount_text}>pH7</Typography>
			</div>
			<div css={bar_container}>
				<div css={empty_bar}/>
				<div css={middle_bar}/>
			</div>

			<div css={label_container}>
				<Typography>
					原料の湯の花エキス <span css={label_sub_text}>| 酸性</span>
				</Typography>
				<Typography variant='h6' css={amount_text}>pH1.5</Typography>
			</div>
			<div css={bar_container}>
				<div css={empty_bar}/>
				<div css={min_bar}/>
			</div>

			<div css={note_container}>
				<Typography variant='h6'>アルカリ湯質(pH8.5~)の特徴</Typography>
				<Typography color='textSecondary' variant='h6' css={note_description}>
					無色透明で湯疲れしにくく、まろやかな入り心地。<br/>
					名湯と言われる温泉に多く見られる泉質です。
				</Typography>
			</div>
		</div>
	)
}

export default EffectsFigure
