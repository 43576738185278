import { css } from '@emotion/core'

export const popup = css`
	line-height: 128%;
	border-radius: 50%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`