import React, { ComponentPropsWithRef } from 'react'
import BackgroundImage from 'gatsby-background-image'
import Typography from '~/components/atoms/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import { thumbnail, description, body, card } from './styles'

interface CardProps extends ComponentPropsWithRef<'div'> {
}

export const Card = ({ ...props }: CardProps): JSX.Element => {
    const { sbn_08 } = useStaticQuery<GatsbyTypes.DailymineralHeaderCardQuery>(graphql`
        query DailymineralHeaderCard {
            sbn_08: file(relativePath: {eq: "sbn_08.jpg"}) {
			    childImageSharp {
			        fluid(maxWidth: 500) {
			            ...GatsbyImageSharpFluid_withWebp
			        }
			    }
		    }
        }
    `)
    return (
        <div css={card} {...props}>
            <BackgroundImage css={thumbnail} fluid={sbn_08.childImageSharp.fluid} />
            <div css={body}>
                <Typography variant='body1'>温泉由来なのに、アクのない香り。</Typography>
                <Typography color='textSecondary' variant='body2'
                            css={description}>「湯の花」と聞くと、独特な硫黄臭を思い浮かべる方も多いのではないでしょうか。デイリーミネラルは、温泉成分そのままに湯の花から硫黄を取り除き、精製したエキスを基剤配合しています。これまで硫黄の匂いで敬遠していた方も、是非お試しくださいませ。</Typography>
            </div>
        </div>
    )
}