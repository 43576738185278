import * as React from 'react'
import Icon from '~/components/atoms/Icon'
import { css } from '@emotion/core'

const icon = css`
	:after {
		content: "\\e901";
	}
`

const AllowLeftIcon = ({...props}): JSX.Element => <Icon css={icon} {...props}/>

export default AllowLeftIcon