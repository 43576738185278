import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	background-color: ${palette.grey['500']};
	padding: 40px;
	border-radius: 20px;
`

export const title = css`
	font-size: 2.4rem;
`

export const catch_amount_text = css`
	position: absolute;
	bottom: 7px;
	right: 0;
`

export const amount_text = css`
	position: absolute;
	bottom: 10px;
	right: 0;
`

export const highlight_text = css`
	color: ${palette.primary.main};
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 2.4rem;
	}
`

export const catch_label_container = css`
	margin-top: 22px;
	margin-bottom: 14px;
	position: relative;
`

export const label_container = css`
	margin-top: 20px;
	margin-bottom: 10px;
	position: relative;
`

export const label_sub_text = css`
	color: ${palette.text.secondary};
`

export const bar_container = css`
	position: relative;
`

export const max_bar = css`
	height: 8px;
	border-radius: 4px;
	background-color: ${palette.primary.main};
`

export const empty_bar = css`
	height: 8px;
	border-radius: 4px;
	background-color: ${palette.common.white};
`

export const middle_bar = css`
	position: absolute;
	top: 0;
	height: 8px;
	width: 77%;
	border-radius: 4px;
	background-color: ${palette.primary.main};
`

export const min_bar = css`
	position: absolute;
	top: 0;
	height: 8px;
	width: 70px;
	border-radius: 4px;
	background-color: ${palette.primary.main};
`

export const note_container = css`
	margin-top: 40px;
	padding: 20px;
	background-color: ${palette.common.white};
	border-radius: 8px;
`

export const note_description = css`
	margin-top: 10px;
	font-weight: 500;
	line-height: 160%;
`