import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	padding-top: 40px;
	padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 80px;
		display: flex;
		justify-content: space-between;
		align-items: start;
		flex-direction: row-reverse;
	}
`

export const bg_gray = css`
	background-color: ${palette.grey['500']};
`

export const thumbnail = css`
	overflow: hidden;
	border-radius: 12px;
	height: 223px;

	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(58% - 30px);
		height: 427px;
	}
`

export const contents = css`
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(42% - 30px);
	}
`

export const category = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const title = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 3.2rem;
	}
`

export const description = css`
	margin-top: 10px;
	font-weight: normal;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const app_description_list = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const app_description_list_item_first = css`
	border-radius: 12px 12px 0 0;
`

export const app_description_list_item_end = css`
	border-radius: 0 0 12px 12px;
	margin-bottom: 0;
`

export const app_description_list_item = css`
	background-color: ${palette.common.white};
	padding: 12px;
	margin-bottom: 1px;
`

export const app_description_text = css`
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 1.6rem;
	}
`

export const app_link_button = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const app_description_sub = css`
	margin-top: 20px;
	font-weight: normal;
	font-size: 1.2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 30px;
	}
`

export const pc_hide = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const sp_hide = css`
	@media (max-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const highlight_text = css`
	color: ${palette.primary.main};
`