import React, { FC } from 'react'
import Typography from '~/components/atoms/Typography'
import {
	amount_text,
	bar_container, catch_amount_text, catch_label_container, component_title,
	container,
	container_end,
	container_first,
	empty_bar, highlight_text, label_container, label_sub_text,
	little_bar,
	max_bar,
	min_bar, note_text,
} from './styles'

const ComponentsFigure: FC = ({ ...props }) => {
	return (
		<div {...props}>
			<div css={[container, container_first]}>
				<Typography color='textPrimary' variant='h5' css={component_title}>1000倍の総鉄イオン量</Typography>
				<div css={catch_label_container}>
					<Typography>湯の花エキス</Typography>
					<Typography css={[highlight_text, catch_amount_text]}>20,000mg/kg</Typography>
				</div>
				<div css={max_bar} />
				<div css={label_container}>
					<Typography css={label_sub_text}>療養泉の基準値</Typography>
					<Typography css={[label_sub_text, amount_text]}>20mg/kg</Typography>
				</div>
				<div css={bar_container}>
					<div css={empty_bar}/>
					<div css={min_bar}/>
				</div>
				<Typography color='textSecondary' css={note_text}>*温泉のうち、特に治療用途に適した鉱泉の総称のこと</Typography>
			</div>
			<div css={[container, container_end]}>
				<Typography color='textPrimary' variant='h5' css={component_title}>入浴剤に含まれるミネラルの数</Typography>
				<div css={catch_label_container}>
					<Typography>湯の花エキス配合の入浴剤</Typography>
					<Typography css={[highlight_text, catch_amount_text]}>62</Typography>
				</div>
				<div css={max_bar} />
				<div css={label_container}>
					<Typography css={label_sub_text}>湯の花エキス無しの入浴剤</Typography>
					<Typography css={[label_sub_text, amount_text]}>6</Typography>
				</div>
				<div css={bar_container}>
					<div css={empty_bar}/>
					<div css={little_bar}/>
				</div>
				<Typography color='textSecondary' css={note_text}>*製造販売元の入浴剤比</Typography>
			</div>
		</div>
	)
}

export default ComponentsFigure
