import React from 'react'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import Button from '~/components/atoms/Button'

import {
    container,
    bg_gray,
    title,
    description,
    double_btn,
    double_btn__left,
    double_btn__left_container,
    double_btn__right,
    double_btn__right_container, sp_hide, pc_hide
} from './styles'

const Support = (): JSX.Element => {
    return (
        <div css={bg_gray}>
            <Container css={container}>
                <Typography css={title} variant='h2' component='h2' color='textPrimary'>サポートが<br css={pc_hide} />必要ですか？</Typography>
                <Typography css={description} variant='body1'
                            component='p'>よくある質問を参考にしたり、<br css={sp_hide} />サポートフォームからお問い合わせください。</Typography>

                <div css={double_btn}>
                    <a css={double_btn__left_container} href='https://on-sen.zendesk.com/hc/ja'>
                        <Button css={double_btn__left}>よくある質問を見る</Button>
                    </a>
                    <a css={double_btn__right_container} href='https://on-sen.zendesk.com/hc/ja/requests/new'>
                        <Button css={double_btn__right}>問い合わせる</Button>
                    </a>
                </div>
            </Container>
        </div>
    )
}

export default Support