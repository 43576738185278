import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

const primary = css`
	color: ${palette.common.white};
	background-color: ${palette.primary.main};
`

const gray = css`
    background-color: ${palette.grey['500']};
`

export const colorTheme = {
    primary,
    gray
}