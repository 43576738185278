import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const pc_hide = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const sp_hide = css`
    display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
	}
`

export const bg_gray = css`
	background-color: ${palette.grey['500']};
`

export const container = css`
	padding-top: 40px;
	padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
`

export const title = css`
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const description = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const double_btn = css`
	margin-top: 20px;
	display: flex;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-left: auto;
		margin-right: auto;
		width: 42%;
		margin-top: 40px;
	}
`

export const double_btn__left_container = css`
	width: 58%;
`

export const double_btn__right_container = css`
	width: 42%;
`

export const double_btn__left = css`
	border-radius: 22px 0 0 22px;
	// material-uiのborderを打ち消す
	border-right: none !important;
`
export const double_btn__right = css`
	border-radius: 0 22px 22px 0;
`