import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const card = css`
	border-radius: 12px;
	overflow: hidden;
	border: 1px solid ${palette.grey['500']};
`

export const title = css`
	margin-top: 10px;
	font-size: 2.4rem;
`

export const subtitle = css`
	font-size: 1.6rem;
`

export const body = css`
	padding: 20px;
`