import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Wholesale from '~/components/pages/Wholesale'
import SEO from '~/utils/seo'

export default function WholesalePage({ location, data }: PageProps<GatsbyTypes.WholesaleQuery>) {
    return (
        <>
            <SEO
                title={'【業務用サイズ】薬用入浴剤 デイリーミネラル A01 Hinoki | Onsen*'}
                description={'温浴施設を運営のお客様で、SNSを使った施設をPRや、新規顧客の獲得、薬湯のバリエーションのお悩みを抱えている方はいませんか？Onsen*はこのような悩みを抱えてる温浴施設さまをと共に、一緒にSNSを盛り上げ、銭湯を含め温浴施設で話題の薬湯となる入浴剤をご提供し温浴文化を広めるサポートをいたします。'}
                url={location.href}
            />
            <Wholesale data={data} />
        </>
    )
}

export const query = graphql`
    query Wholesale {
        microcmsDailymineral(handle: {eq: "wholesale"}) {
            handle
            title
            catch
            media {
                image {
                    url
                }
            }
            price
            components {
                description
                title
            }
            details {
                description
                title
            }
            effects {
                html
            }
            fragranceImage {
                url
            }
        }
    }
`