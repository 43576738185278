import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	padding-top: 40px;
	padding-bottom: 32px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 56px;
		padding-bottom: 72px;
	}
`

export const title = css`
	margin-top: 10px;
	font-size: 3.2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		text-align: center;
	}
`
export const subtitle = css`
	font-size: 1.6rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2rem;
		text-align: center;
	}
`
export const description = css`
	margin-top: 20px;
	font-size: 2rem;
	font-weight: 500;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 35px;
		text-align: center;
	}
`

export const card_list = css`
	margin-top: 20px;
	display: grid;
	gap: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 24px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 56px;
	}
`

export const benefits = css`
	margin-top: 40px;

	> * + * {
		margin-top: 40px;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		> * + * {
			margin-top: 24px;
		}
	}
`

export const benefit = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		justify-content: space-between;
		align-items: self-start;

		> * + * {
			min-width: 290px;
			margin-left: 34.5px;
		}
	}
`

export const benefit__title = css`
	font-size: 2.4rem;
`

export const benefit__subtitle = css`
	font-size: 2rem;
	font-weight: 500;
`

export const benefit__description = css`
	margin-top: 20px;
	font-size: 1.6rem;
	font-weight: 500;
`

export const card_1__thumbnail = css`
	height: 218px;
	background-color: ${palette.grey['500']};
	background-size: contain !important;
    background-position: top 24px center !important;
    ::before, ::after {
	    background-position: top 24px center !important;
	    background-size: contain !important;
    }
`

export const card_2__thumbnail = css`
	height: 218px;
`

export const benefit__header = css`
	display: flex;

	> * + * {
		margin-left: 20px;
		flex: 1;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		> * + * {
			margin-left: 16px;
			flex: 1;
		}
	}
`

export const popup = css`
	font-size: 1.6rem;
	line-height: 28px;

	> * + * {
		font-size: 2.4rem;
	}
`

export const sample_images = css`
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	> * {
		width: calc(50% - 3.5px);
	}
`

export const sample_image_with_caption = css`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`

export const sample_images__caption = css`
	font-weight: 500;
	font-size: 12px;
`

export const present_card = css`
	margin-top: 20px;
	position: relative;
	height: 196px;
	border-radius: 20px;
	overflow: hidden;
`

export const present_card__body = css`
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 20px 22px;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: self-start;
	justify-content: space-between;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 19px 17px;
	}
`

export const present_card__title = css`
	font-size: 2rem;
	color: ${palette.primary.main};
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`

export const present_card__tag = css`
	font-size: 1.6rem;
	color: ${palette.common.white};
	padding: 6px 15px;
	background-color: ${palette.primary.main};
	border-radius: 4px;
	line-height: 20px;
`