import React, { FC, useState } from 'react'
import { childImages, sliderContainer } from '~/components/molecules/PCSlider/styles'

type Props = {
	media: any
}

const PCSlider: FC<Props> = ({ media, ...props }) => {
	const [selectImageNumber, setSelectImageNumber] = useState(0)

	return (
		<div css={sliderContainer} {...props}>
			<div>
				{media[selectImageNumber] && (
					<img src={media[selectImageNumber].image.url}/>
				)}
			</div>
			<div css={childImages}>
				{media.map((content, index) => {
					return (
						<div key={content.image.url} onClick={() => setSelectImageNumber(index)}>
							<img src={content.image.url} alt='' />
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default PCSlider