import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { Container } from '~/components/layout/Container'
import { graphql, useStaticQuery } from 'gatsby'
import Typography from '~/components/atoms/Typography'
import { container, title, sp_hide, description, bg_img } from './styles'

export const Feature = (): JSX.Element => {
    const { concept_img } = useStaticQuery<GatsbyTypes.FeatureQuery>(graphql`
        query Feature {
            concept_img: file(relativePath: {eq: "concept_img.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return (
        <BackgroundImage css={bg_img} fluid={concept_img.childImageSharp.fluid}>
            <Container css={container}>
                <Typography variant='h2' css={title} >入浴剤の特徴</Typography>
                <Typography variant='body1' css={description}>
                    温泉発、全身トリートメントとしての“整え”体験。<br css={sp_hide} />
                    「薬用入浴剤 デイリーミネラル」は、<br css={sp_hide} />
                    温泉力を日常に取り入れられるよう開発された、サプリのような入浴剤です。<br css={sp_hide} />
                    毎日の何気ない入浴が、あなたの心と身体を整える<br css={sp_hide} />
                    「ミネラル入浴」に変わります。
                </Typography>
            </Container>
        </BackgroundImage>
    )
}