import { css } from '@emotion/core'

export const sliderContainer = css`
	overflow: hidden;
	border-radius: 20px;
`

export const childImages = css`
	margin-top: 1px;
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	gap: 0.8px;
`