import React from 'react'
import Icon from '~/components/atoms/Icon'
import { dot } from './styles'

const DotIcon = ({ ...props }) => {
	return (
		<Icon css={dot} {...props} />
	)
}

export default DotIcon
