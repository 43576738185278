import React, { ComponentPropsWithRef, ReactNode } from 'react'
import { tag } from './styles'

interface TagProps extends ComponentPropsWithRef<"span"> {
	children?: ReactNode
}

const Tag = ({ children, ...props }: TagProps) => {
	return (
		<span css={tag} {...props}>{children}</span>
	)
}

export default Tag