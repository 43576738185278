import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const bg_gray = css`
	background-color: ${palette.grey['500']};
`

export const container = css`
    padding-top: 20px;
    padding-bottom: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
`

export const icon_list = css`
    display: flex;
    justify-content: space-between;
`

export const item = css`
	width: calc(92% / 3);
	text-align: center;
`

export const icon = css`
	width: 40px;
	margin: 0 auto;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 80px;
	}
`

export const caption = css`
	margin-top: 10px;
	font-weight: bold;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 2rem;
	}
`