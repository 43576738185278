import React from 'react'

import Facility from '~/components/organisms/Facility'
import Benefits from '~/components/organisms/Benefits'
import Voice from '~/components/organisms/Voice'
import OurCustomer from '~/components/organisms/OurCustomer'
import FAQ from '~/components/organisms/FAQ'
import Support from '~/components/organisms/Support'
import NewsList from '~/components/organisms/NewsList'
import AppRecommend from '~/components/organisms/AppRecomend'
import { Feature } from './Feature'
import FeatureIcons from '~/components/organisms/FeatureIcons'
import DailymineralHeader from '~/components/organisms/DailymineralHeader'
import DailymineralBody from '~/components/organisms/DailymineralBody'
import { Header } from '~/components/pages/Wholesale/Header'

type Props = {
    data: GatsbyTypes.WholesaleQuery
}

const Wholesale: React.VFC<Props> = ({ data: { microcmsDailymineral} }) => {
    return (
        <>
            {/* ヘッダー部分 */}
            <Header
                media={microcmsDailymineral.media}
            />
            {/* onsen * 温浴施設 */}
            <Facility />
            {/* benefits */}
            <Benefits />
            {/* voice */}
            <Voice/>
            {/* our customer */}
            <OurCustomer />
            {/* faq */}
            <FAQ />
            {/* 入浴剤の特徴 */}
            <Feature />
            {/* 入浴剤の特徴 */}
            <FeatureIcons />
            {/* コンテンツ部分 microcms管理 その1 */}
            <DailymineralHeader
                fragranceImage={microcmsDailymineral.fragranceImage}
                components={microcmsDailymineral.components}
                catchTitle={microcmsDailymineral.catch}
            />
            {/* アプリについて */}
            <AppRecommend />
            {/* コンテンツ部分 microcms管理 その2 */}
            <DailymineralBody
                effects={microcmsDailymineral.effects}
                details={microcmsDailymineral.details}
            />
            {/* サポートが必要ですか? */}
            <Support />
            {/* ニュース・メディア掲載 */}
            <NewsList />
        </>
    )
}

export default Wholesale