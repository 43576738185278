import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	background-color: ${palette.grey['500']};
	padding: 20px;
	margin-bottom: 1px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 40px;
	}
`

export const container_first = css`
	border-radius: 12px 12px 0 0;
`

export const container_end = css`
	border-radius: 0 0 12px 12px;
	margin-bottom: 0;
`

export const component_title = css`
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 2.4rem;
	}
`

export const max_bar = css`
	height: 4px;
	border-radius: 4px;
	background-color: ${palette.primary.main};
	@media (min-width: ${breakpoints.tablet}px) {
		height: 8px;
	}
`

export const bar_container = css`
	position: relative;
`

export const empty_bar = css`
	height: 4px;
	border-radius: 4px;
	background-color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
		height: 8px;
	}
`

export const min_bar = css`
	position: absolute;
	top: 0;
	height: 4px;
	width: 4px;
	border-radius: 4px;
	background-color: ${palette.primary.main};
	@media (min-width: ${breakpoints.tablet}px) {
		height: 8px;
		width: 8px;
	}
`

export const little_bar = css`
	position: absolute;
	top: 0;
	height: 4px;
	width: 22px;
	border-radius: 4px;
	background-color: ${palette.primary.main};
	@media (min-width: ${breakpoints.tablet}px) {
		height: 8px;
		width: 40px;
	}
`

export const catch_amount_text = css`
	@media (min-width: ${breakpoints.tablet}px) {
		position: absolute;
		bottom: 7px;
		right: 0;
	}
`

export const amount_text = css`
	@media (min-width: ${breakpoints.tablet}px) {
		position: absolute;
		bottom: 10px;
		right: 0;
	}
`

export const catch_label_container = css`
	margin-top: 22px;
	margin-bottom: 14px;
	display: flex;
	justify-content: space-between;
	@media (min-width: ${breakpoints.tablet}px) {
		position: relative;
	}
`

export const label_container = css`
	margin-top: 26px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	@media (min-width: ${breakpoints.tablet}px) {
		position: relative;
	}
`

export const label_sub_text = css`
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 1.6rem;
	}
`

export const highlight_text = css`
	color: ${palette.primary.main};
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 2.4rem;
	}
`

export const note_text = css`
	margin-top: 10px;
	font-size: 1.2rem;
	font-weight: normal;
`