import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const card = css``
export const name = css`
	color: ${palette.primary.main};
	font-size: 1.8rem;
`
export const address = css`
	margin-top: 4px;
	font-size: 1.4rem;
	font-weight: 500;
`
export const phone = css`
	margin-top: 1px;
	font-size: 1.4rem;
	font-weight: 500;
`
export const tags = css`
	margin-top: 14px;
	display: flex;
	gap: 11px;
	color: ${palette.text.secondary};
`