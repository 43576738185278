import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import Popup from '~/components/atoms/Popup'
import { BenefitCard } from '~/components/organisms/Benefits/BenefitCard'
import {
    benefit, benefit__description,
    benefit__header, benefit__subtitle, benefit__title, benefits,
    card_1__thumbnail,
    card_2__thumbnail,
    card_list,
    container, description, popup,
    present_card,
    present_card__body,
    present_card__tag,
    present_card__title, sample_image_with_caption,
    sample_images,
    sample_images__caption, subtitle, title
} from './styles'
import GatsbyImage from 'gatsby-image'

const Benefits = (): JSX.Element => {
    const {
        benefits_card_1,
        benefits_card_2,
        benefits_sample_1,
        benefits_sample_2
    } = useStaticQuery<GatsbyTypes.BenefitsQuery>(graphql`
        query Benefits {
            benefits_card_1: file(relativePath: {eq: "benefits/benefits-card-1.png"}) {
			    childImageSharp {
			      fluid(maxWidth: 300) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    benefits_card_2: file(relativePath: {eq: "benefits/benefits-card-2.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		   benefits_sample_1: file(relativePath: {eq: "benefits/benefits-sample-1.png"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    benefits_sample_2: file(relativePath: {eq: "benefits/benefits-sample-2.png"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
        }
    `)

    return (
        <Container css={container}>
            <Typography css={subtitle} color='textSecondary'>Benefits</Typography>
            <Typography css={title} color='textPrimary'>初回購入特典</Typography>
            <Typography
                css={description}>業務用サイズをお買い上げいただいた方だけに、購入特典をお付けします。<br />今だけ提供しているサービス特典です。期間限定ですのでお早めに！</Typography>
            {/* cards */}
            <ul css={card_list}>
                <BenefitCard
                    thumbnail={
                        <BackgroundImage css={card_1__thumbnail} fluid={benefits_card_1.childImageSharp.fluid} />
                    }
                    subtitle={'特典1~新規お客様の送客~'}
                    title={'店舗紹介ページを優先作成'}
                />
                <BenefitCard
                    thumbnail={
                        <BackgroundImage css={card_2__thumbnail} fluid={benefits_card_2.childImageSharp.fluid} />
                    }
                    subtitle={'特典2~PR支援~'}
                    title={'お客様用に入浴剤を30袋<br/>プレゼント＆SNSサポート'}
                />
            </ul>
            {/* 特典 */}
            <div css={benefits}>
                <div css={benefit}>
                    <div>
                        <div css={benefit__header}>
                            <Popup css={popup}>
                                <span>特典</span>
                                <span>1</span>
                            </Popup>
                            <div>
                                <Typography css={benefit__title} color='textPrimary'>店舗紹介ページを優先作成</Typography>
                                <Typography css={benefit__subtitle}>デザインのプロによる高品質なページ</Typography>
                            </div>
                        </div>
                        <Typography
                            css={benefit__description}>Onsen*に掲載する店舗ページを無料で掲載いたします。温浴施設を探すお客さま向けに、お店の魅力と正しい情報をしっかり伝えられる施設紹介サイトが無料で手に入ります。</Typography>
                    </div>

                    <div css={sample_images}>
                        <GatsbyImage
                            fluid={benefits_sample_2.childImageSharp.fluid}
                        />
                        <div css={sample_image_with_caption}>
                            <GatsbyImage
                                fluid={benefits_sample_1.childImageSharp.fluid}
                            />
                            <Typography css={sample_images__caption}>※サンプル画像</Typography>
                        </div>
                    </div>
                </div>
                <div css={benefit}>
                    <div>
                        <div css={benefit__header}>
                            <Popup css={popup}>
                                <span>特典</span>
                                <span>2</span>
                            </Popup>
                            <div>
                                <Typography css={benefit__title} color='textPrimary'>SNS支援と入浴剤を30袋提供</Typography>
                                <Typography css={benefit__subtitle}>お客様に喜んでいただく企画＆ギフト</Typography>
                            </div>
                        </div>
                        <Typography
                            css={benefit__description}>購入いただいた店舗様にはOnsen*デイリーミネラルを30袋提供いたします。こちらを活用いただき、店舗様のSNSを一緒に盛り上げる企画と集客支援を実施いたします。</Typography>
                    </div>

                    {/*  sns支援カード  */}
                    <BackgroundImage css={present_card} fluid={benefits_card_2.childImageSharp.fluid}>
                        <div css={present_card__body}>
                            <span css={present_card__tag}>SNS支援</span>
                            <Typography css={present_card__title}>デイリーミネラル<br />30袋プレゼント！</Typography>
                        </div>
                    </BackgroundImage>
                </div>
            </div>
        </Container>
    )
}

export default Benefits