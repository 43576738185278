import * as React from 'react'
import SMSlider from '~/components/molecules/SMSlider'
import PCSlider from '~/components/molecules/PCSlider'
import CVButton from '~/components/atoms/CVButton'
import { Container } from '~/components/layout/Container'
import { ComponentPropsWithRef } from 'react'
import {
    link_btn,
    pc_slider,
    product_catch,
    product_info,
    product_price,
    product_title,
    product_top, sm_slider
} from './styles'
import { Typography } from '@material-ui/core'

interface HeaderProps extends ComponentPropsWithRef<'div'> {
    media: any
}

export const Header = ({ media, ...props }: HeaderProps): JSX.Element => {
    return (
        <Container css={[product_top]} {...props}>
            <SMSlider css={sm_slider}>
                {media.map((content) => (
                    <div
                        key={content.image.url}
                    >
                        <img
                            src={content.image.url}
                        />
                    </div>
                ))}
            </SMSlider>
            <PCSlider media={media} css={pc_slider} />

            <div>
                <Typography css={product_catch} variant='subtitle1' color='textSecondary'>業務用サイズ</Typography>
                <Typography css={product_title} variant='h4' color='textPrimary' component='h1'>薬用入浴剤<br/>デイリーミネラル<br/>A01 Hinoki 業務用</Typography>
                <Typography css={product_price} variant='subtitle1'>15kg~/お問い合わせ</Typography>

                <div css={product_info}>
                    <Typography variant='body2' color='textSecondary' component='p'>
                        本商品は銭湯や温浴施設さま向けの業務用商品です。<br/>
                        本商品はカートからご購入いただくことはできません。<br/>
                        価格を知りたい/ご購入を希望される方は下記ボタンからお問い合わせください。<br/>
                        3営業日以内にこちらから返信差し上げます。
                    </Typography>
                </div>

                <a href='https://on-sen.zendesk.com/hc/ja/requests/new'>
                    <CVButton css={link_btn}>問い合わせる</CVButton>
                </a>
            </div>
        </Container>
    )
}