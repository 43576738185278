import React from 'react'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import MINERAL from '~/images/mineral.svg'
import BATH from '~/images/bath-icon.svg'
import AROMA from '~/images/aroma-icon.svg'
import { bg_gray, caption, container, icon, icon_list, item } from './styles'

const FeatureIcons = (): JSX.Element => {
    return (
        <div css={bg_gray}>
            <Container css={container}>
                <ul css={icon_list}>
                    <li css={item}>
                        <figure>
                            <img src={AROMA} alt='' css={icon} />
                            <figcaption>
                                <Typography variant='subtitle2' css={caption}>アロマの<br />フレグランス</Typography>
                            </figcaption>
                        </figure>
                    </li>
                    <li css={item}>
                        <figure>
                            <img src={MINERAL} alt='' css={icon} />
                            <figcaption>
                                <Typography variant='subtitle2' css={caption}>豊潤な<br />ミネラル成分</Typography>
                            </figcaption>
                        </figure>
                    </li>
                    <li css={item}>
                        <figure>
                            <img src={BATH} alt='' css={icon} />
                            <figcaption>
                                <Typography variant='subtitle2' css={caption}>家でも楽しめる<br />トロトロ温泉感</Typography>
                            </figcaption>
                        </figure>
                    </li>
                </ul>
            </Container>
        </div>
    )
}

export default FeatureIcons