import React, { ComponentPropsWithRef } from 'react'
import { border } from './styles'

interface HorizonProps extends ComponentPropsWithRef<'hr'> {
}

const Horizon = ({ ...props }: HorizonProps): JSX.Element => {
    return (
        <hr css={border} {...props} />
    )
}

export default Horizon