import React, { ComponentPropsWithRef, ReactNode } from 'react'
import Typography from '~/components/atoms/Typography'
import * as styles from './styles'

interface BenefitCardProps extends ComponentPropsWithRef<'figure'> {
    thumbnail: ReactNode
    subtitle: string
    title: string
}

export const BenefitCard = ({ thumbnail, subtitle, title, ...props }: BenefitCardProps): JSX.Element => {
    return (
        <figure css={styles.card} {...props}>
            {thumbnail}
            <figcaption css={styles.body}>
                <Typography css={styles.subtitle} color='textSecondary'>{subtitle}</Typography>
                <Typography css={styles.title} color='textPrimary' dangerouslySetInnerHTML={{ __html: title }} />
            </figcaption>
        </figure>
    )
}