import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const sp_hide = css`
	@media (max-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const bg_img = css`
    background-position: center;
    ::before, ::after {
	    background-color: rgba(0, 0, 0, 0.32);
	    background-blend-mode: darken;
    }
`

export const container = css`
    padding-top: 80px;
    padding-bottom: 80px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
`

export const title = css`
    color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
        font-size: 4rem;
        line-height: 51px;
		text-align: center;
	}
`

export const description = css`
	margin-top: 20px;
    line-height: 32px;
	color: ${palette.common.white};
	font-weight: normal;
	@media (min-width: ${breakpoints.tablet}px) {
        font-size: 2rem;
		text-align: center;
	}
`