import React, { FC } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import DotIcon from '~/components/molecules/DotIcon'
import { dotsContainer, dot, container } from '~/components/molecules/SMSlider/styles'

const CustomDots = (props) => {
	return (
		<ul css={dotsContainer}>{props}</ul>
	)
}

const CustomPaging = () => {
	return (
		<DotIcon css={dot}/>
	)
}

const settings = {
	dots: true,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	appendDots: CustomDots,
	customPaging: CustomPaging,
}

const SMSlider: FC = ({ children, ...props }) => {
	return (
		<Slider css={container} {...settings} {...props}>
			{children}
		</Slider>
	)
}

export default SMSlider