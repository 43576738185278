import React, { ComponentPropsWithRef } from 'react'
import Typography from '~/components/atoms/Typography'
import Popup from '~/components/atoms/Popup'

import { body, card, header, horizon, popup, title } from './styles'
import Horizon from '~/components/atoms/Horizon'

interface QuestionCardProps extends ComponentPropsWithRef<'div'> {
    question: string
    answer: string
}

export const QuestionCard = ({ question, answer, ...props }: QuestionCardProps): JSX.Element => {
    return (
        <div css={card} {...props}>
            <div css={header}>
                <Popup css={popup} size='medium' color='gray'>Q</Popup>
                <Typography css={title} color='textPrimary'>{question}</Typography>
            </div>
            <Horizon css={horizon}/>
            <Typography css={body}>{answer}</Typography>
        </div>
    )
}