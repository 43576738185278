import React from 'react'
import { Container } from '~/components/layout/Container'
import BackgroundImage from 'gatsby-background-image'
import Typography from '~/components/atoms/Typography'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Button from '~/components/atoms/Button'
import {
    app_description_list,
    app_description_list_item,
    app_description_list_item_end,
    app_description_list_item_first, app_description_sub,
    app_description_text,
    app_link_button,
    bg_gray,
    category,
    container,
    contents,
    description, highlight_text, pc_hide, sp_hide,
    thumbnail,
    title
} from './styles'

const AppRecommend = (): JSX.Element => {
    const { app_img } = useStaticQuery<GatsbyTypes.AppRecommendQuery>(graphql`
        query AppRecommend {
            app_img: file(relativePath: {eq: "img_05.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
		    }
        }
    `)
    return (
        <div css={bg_gray}>
            <Container css={container}>
                <BackgroundImage fluid={app_img.childImageSharp.fluid} css={thumbnail} />
                <div css={contents}>
                    <Typography variant='subtitle1' css={category}>アプリ</Typography>
                    <Typography variant='h4' color='textPrimary' css={title}>医師監修の専用アプリで<br />
                        もっと
                        <span css={pc_hide}>ポカポカ</span>
                        <span css={[highlight_text, sp_hide]}>ぽかぽか</span>。
                        <span css={sp_hide}><br />そして<span css={highlight_text}>スヤスヤ</span>。</span>
                    </Typography>
                    <Typography variant='body1' css={description}>
                        入浴剤をスマホでスキャン。アプリと連動し、香りの気分やムードを高める専用BGMが楽しめます。
                    </Typography>

                    <ul css={app_description_list}>
                        <li css={[app_description_list_item, app_description_list_item_first]}>
                            <Typography variant='body2' css={app_description_text}>1. 裏面のQRをスマホカメラでスキャン。</Typography>
                        </li>
                        <li css={[app_description_list_item, app_description_list_item_end]}>
                            <Typography variant='body2' css={app_description_text}>2. アプリが連動して専用レシピとBGMを提供。</Typography>
                        </li>
                    </ul>

                    <Link to='/pages/app'>
                        <Button css={app_link_button}>アプリについて詳しく見る</Button>
                    </Link>

                    <Typography css={app_description_sub} color='textSecondary'>
                        ※ 対応機種は現在iOS端末のみとなります。事前に入浴アプリOnsen*のインストールが必要です。アプリを未入手の状態で入浴剤のQRコードスキャンを行うと、App Storeページが開きます。
                    </Typography>
                </div>
            </Container>
        </div>
    )
}

export default AppRecommend