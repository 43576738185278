import React from 'react'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import { QuestionCard } from '~/components/organisms/FAQ/QuestionCard'
import { bg_gray, cards, container, link, subtitle, title } from './styles'

const FAQ = (): JSX.Element => {
    return (
        <div css={bg_gray}>
            <Container css={container}>
                <Typography css={subtitle} color='textSecondary'>FAQ</Typography>
                <Typography css={title} color='textPrimary'>よくあるご質問</Typography>

                <ul css={cards}>
                    <li>
                        <QuestionCard
                            question={'色素は含まれますか？退色はしますか？'}
                            answer={'Hinokiには色素を配合しておりません。なので退色の影響は軽微です'}
                        />
                    </li>
                    <li>
                        <QuestionCard
                            question={'送料はどちらが負担しますか？'}
                            answer={'おそれいりますが、お客様にご負担いただいております。'}
                        />
                    </li>
                    <li>
                        <QuestionCard
                            question={'風呂がまや機械への影響はありますか？'}
                            answer={'結論は安心してお使いいただけます。ただし、店舗ごとにメンテナンス状況が異なるため絶対に問題がないとは言い切れません。参考までにいままでの導入店舗から清掃後に機器を確認いただき問題があった報告はいただいておりません。'}
                        />
                    </li>
                    <li>
                        <QuestionCard
                            question={'最小の購入ロットはありますか？'}
                            answer={'1セット以上 = 15kg以上 からお願いいたします。'}
                        />
                    </li>
                </ul>

                <a href='https://on-sen.zendesk.com/hc/ja'>
                    <Typography css={link} color='primary'>その他の質問はこちらをご覧ください</Typography>
                </a>
            </Container>
        </div>
    )
}

export default FAQ