import React, { ComponentPropsWithRef } from 'react'
import Typography from '~/components/atoms/Typography'
import Tag from '~/components/atoms/Tag'
import * as styles from './styles'

interface FacilityCardProps extends ComponentPropsWithRef<'div'> {
    name: string
    address: string
    phone: string
    tags: Array<string>
}

export const FacilityCard = ({ name, address, phone, tags, ...props }: FacilityCardProps): JSX.Element => {
    return (
        <div css={styles.card} {...props}>
            <Typography css={styles.name}>{name}</Typography>
            <Typography css={styles.address} color='textSecondary'>{address}</Typography>
            <Typography css={styles.phone} color='textSecondary'>{phone}</Typography>
            <ul css={styles.tags}>
                {tags.map((tag, index) => (
                    <li key={`tag-${index}`}>
                        <Tag>{tag}</Tag>
                    </li>
                ))}
            </ul>
        </div>
    )
}