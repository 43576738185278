import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const tag = css`
	background-color: ${palette.common.white};
	border: 1px solid ${palette.text.secondary};
	display: inline-block;
	font-size: 1.4rem;
	line-height: 32px;
	padding: 0 12px;
	border-radius: 4px;
	overflow: hidden;
	font-weight: 500;
`