import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const sp_hide = css`
    display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
	}
`

export const pc_hide = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const bg_grey = css`
    padding: 20px;
	background-color: ${palette.grey['500']};
	@media (min-width: ${breakpoints.tablet}px) {
		padding-bottom: 52px;
	}
`

export const facility__header = css`
    padding: 38px 0;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 78px 0 228px 0;
	}
`

export const facility__icons = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

export const facility__icon = css`
    text-align: center;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-right: 24px;
	}
`

export const facility__title = css`
    font-size: 3.2rem;
	text-align: center;
	color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 4.2rem;
        font-weight: 500;
	}
`

export const facility__subtitle = css`
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 1.6rem;
	color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2.8rem;
	}
`

export const facility__text = css`
    margin-top: 40px;
    font-size: 2.4rem;
    line-height: 40px;
    text-align: center;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 48px;
	}
`

export const problems = css`
    max-width: 900px;
	margin-left: auto;
	margin-right: auto;
    position: relative;
    padding: 40px 16px 40px 26px;
    border-radius: 12px;
    background-color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
        margin-top: -200px;
		padding: 52px 56px 50px 57px;
	}
`

export const problems__header = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
        justify-content: space-between;
	}
`

export const problems__body = css`
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0px;
	}
`

export const problems__icon = css`
    margin-top: 20px;
    text-align: center;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const problems__title = css`
    margin-top: 20px;
    font-size: 2.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2.8rem;
	}
`

export const problems__caption = css`
    margin-top: 20px;
    text-align: center;
    color: ${palette.primary.main};
    font-size: 2.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
        margin-top: 0px;
		text-align: left;
	}
`

export const problems__list = css`
    margin-top: 20px;
    padding: 0 23.5px;
    display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 24px;
        padding: 0;
	}
`

export const problems__item = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 44px 12px;
    border-radius: 12px;
    height: 100%;
    background-color: ${palette.grey['500']};
    p {
        font-size: 2rem;
    }
`

export const proposals = css`
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
	position: relative;
	padding: 40px 16px 40px 26px;
	border-radius: 12px;
	background-color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
        margin-top: 50px;
		padding: 52px 56px 50px 57px;
	}
`

export const proposals__header = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		justify-content: space-between;
	}
`

export const proposals__body = css`
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0px;
	}
`

export const proposals__title = css`
	margin-top: 20px;
	font-size: 2.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
        margin-top: 0;
		font-size: 2.8rem;
	}
`

export const proposals__text = css`
    margin-top: 20px;
    font-weight: 500;
    font-size: 1.6rem;
	@media (min-width: ${breakpoints.tablet}px) {
        margin-top: 10px;
		font-size: 2rem;
	}
`

export const proposals__icon = css`
	margin-top: 20px;
	text-align: center;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const proposals__caption = css`
	margin-top: 20px;
	text-align: center;
	color: ${palette.primary.main};
	font-size: 2.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0px;
		text-align: left;
	}
`

export const proposals__list = css`
	margin-top: 20px;
	padding: 0 23.5px;
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 24px;
		padding: 0;
	}
`

export const proposals__item = css`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 44px 12px;
	border-radius: 12px;
	background-color: ${palette.grey['500']};
	p {
		font-size: 2rem;
	}
`