import React, { ComponentPropsWithRef } from 'react'
import { css } from '@emotion/core'
import { popup as base } from './styles'
import { sizeTheme, colorTheme } from './theme'

interface PopupProps extends ComponentPropsWithRef<'span'> {
    size?: 'medium' | 'large'
    color?: 'primary' | 'gray'
}

const Popup = ({ size = 'large', color = 'primary', children, ...props }: PopupProps): JSX.Element => {
    const popup = css`
		${base};
		${sizeTheme[size]};
		${colorTheme[color]};
    `

    return (
        <span css={popup} {...props}>{children}</span>
    )
}

export default Popup