import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const bg_gray = css`
	background-color: ${palette.grey['500']};
`

export const container = css`
	padding-top: 40px;
	padding-bottom: 40px;

	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 56px;
		padding-bottom: 79px;
	}
`

export const cards = css`
	margin-top: 40px;
	display: grid;
	gap: 20px;
`

export const title = css`
	margin-top: 10px;
	font-size: 3.2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		text-align: center;
	}
`
export const subtitle = css`
	font-size: 1.6rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2rem;
		text-align: center;
	}
`

export const link = css`
	margin-top: 20px;
	text-align: center;
	font-size: 1.4rem;

	:hover {
		color: ${palette.primary.light};
	}

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`