import * as React from 'react'
import Icon from '~/components/atoms/Icon'
import { css } from '@emotion/core'

const icon = css`
	:after {
		content: "\\e902";
	}
`

const AllowRightIcon = ({...props}): JSX.Element => <Icon css={icon} {...props}/>

export default AllowRightIcon