import React, { ComponentPropsWithRef } from 'react'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import BackgroundImage from 'gatsby-background-image'
import ComponentsFigure from '~/components/molecules/ComponentsFigure'
import EffectsFigure from '~/components/molecules/EffectsFigure'
import { Card } from './Card'
import { graphql, useStaticQuery } from 'gatsby'
import {
    container,
    article,
    article__contents,
    article__description,
    article__subtitle,
    article__thumbnail,
    article__title,
    components_figure,
    flex_reverse,
    list,
    sp_hide,
    no_margin,
    fragrance_list_container,
    fragrance_list,
    fragrance_list_title,
    fragrance_list_description,
    article_primary_text
} from './styles'

interface DailymineralHeader extends ComponentPropsWithRef<'div'> {
    catchTitle: string
    fragranceImage: {
        url: string
    }
    components: {
        description: string
        title: string
    }[]
}

const DailymineralHeader = ({ catchTitle, fragranceImage, components, ...props }: DailymineralHeader): JSX.Element => {
    const { sbn_01, sbn_02, sbn_09, sbn_10 } = useStaticQuery<GatsbyTypes.DailymineralHeaderQuery>(graphql`
        query DailymineralHeader {
            sbn_01: file(relativePath: {eq: "sbn_01.jpg"}) {
			childImageSharp {
			    fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			    }
			}
		},
		sbn_02: file(relativePath: {eq: "sbn_02.jpg"}) {
			childImageSharp {
			    fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			    }
			}
		},
		sbn_09: file(relativePath: {eq: "sbn_09.jpg"}) {
			childImageSharp {
			    fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			    }
			}
		},
		sbn_10: file(relativePath: {eq: "sbn_10.jpg"}) {
			childImageSharp {
			    fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			    }
			}
		}
        }
    `)
    return (
        <Container css={container} {...props}>
            <ul css={list}>
                <li>
                    <article css={[article, flex_reverse]}>
                        <div css={article__thumbnail} style={{
                            backgroundImage: `url(${fragranceImage.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }} />
                        <div css={article__contents}>
                            <Typography css={article__subtitle}>フレグランス</Typography>
                            <Typography color='textPrimary' css={article__title}
                                        dangerouslySetInnerHTML={{ __html: catchTitle }} />
                            <ul css={fragrance_list_container}>
                                {components.map((component, index) => (
                                    <li css={fragrance_list} key={`${component.title}-${index}`}>
                                        <Typography css={fragrance_list_title}
                                                    color='textPrimary'>{component.title}</Typography>
                                        <Typography css={fragrance_list_description}
                                                    variant='subtitle2'>{component.description}</Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </article>
                </li>
                <li>
                    <article css={article}>
                        <BackgroundImage css={article__thumbnail} fluid={sbn_01.childImageSharp.fluid} />
                        <div css={article__contents}>
                            <Typography css={article__subtitle}>天然のメカニズム</Typography>
                            <Typography css={article__title} color='textPrimary'>大地で育まれる豊潤な温泉ミネラル。</Typography>
                            <Typography css={article__description}>
                                デイリーミネラルの成分の秘密は、日本随一の温泉地である別府温泉にあります。<br /><br />
                                別府はその地理的特性から、世界にも例がないほど多くの温泉が湧きます。
                                湧き続ける温泉は、別府の大地に「青粘土」と呼ばれる非常に特殊な土壌をもたらしました。「学名：モンモリロナイト」と呼ばれるこの青く光る美しい粘土は、湧き出す温泉と地中の岩石が悠久の時を経て反応し生まれた、ミネラルを豊潤に含む奇跡の原料です。<br /><br />
                                この青粘土からミネラル成分だけを結晶化し、「別府湯の花」として純度高く生成していきます。
                            </Typography>
                        </div>
                    </article>
                </li>
                <li>
                    <article css={[article, flex_reverse]}>
                        <BackgroundImage css={article__thumbnail} fluid={sbn_09.childImageSharp.fluid} />
                        <div css={article__contents}>
                            <Typography css={article__description}>
                                「別府湯の花」の生成は、まるで花を栽培しているような特殊な手法で行われます。高温の温泉蒸気が立ち込める別府の大地にわら小屋を立て、小屋の中に青粘土を平たく敷き詰めるのです。わら小屋の中は非常に高温となり、温泉成分を含んだ蒸気で満たされます。<br /><br />
                                この環境の中で、夏は25日、冬は45日から50日の時間をかけることで、敷き詰められた青粘土と温泉蒸気が反応し、表面に厚さ数センチもの霜柱状の白い結晶が咲くのです。その結晶が表面に析出しできたものが、温泉成分の凝縮結晶とも言える天然のミネラル。
                                青粘土に含まれるミネラルはこうして磨き上げられます。 ミネラル分だけが抽出された輝く結晶は、まさに” 温泉水のクリスタル “と呼ぶにふさわしいものです。<br /><br />
                                この「別府湯の花」の技法は江戸時代から変わらず、<span css={article_primary_text}>日本の重要無形民俗文化財</span>として今なお引き継がれています。藁葺き小屋が作り出す天然のメカニズムは、現代の科学を持ってしても真似することができません。長い時間と、自然の力、そして職人の手作業で生成されるこの<span css={article_primary_text}>湯の花結晶は、他の温泉地のものと根源的に違い、温泉由来の微量成分を含み</span>、水分との親和性がとても高いのです。
                            </Typography>
                        </div>
                    </article>
                </li>
                <li>
                    <article css={article}>
                        <BackgroundImage css={article__thumbnail} fluid={sbn_10.childImageSharp.fluid} />
                        <div css={article__contents}>
                            <Typography css={article__description}>
                                ここでまだ終わりではありません。<br /><br />
                                青粘土から析出されたミネラルの結晶は、入浴剤に配合するために液体化する必要があります。湯の花の結晶成分と理想的に適合する天然温泉に融合させながら、約1ヶ月、ゆっくりと濾過が繰り返されます。こうして生まれるのがデイリーミネラルに配合される「別府温泉精製湯の花エキス」です。<br /><br />
                                琥珀色に輝くこのエキスは、幾重もの工程を経る独自製法で、品質が整えられ、<span css={article_primary_text}>さらに天然温泉と融合し、結晶に含まれるミネラル分が余すところなく精製されています</span>。
                            </Typography>
                        </div>
                    </article>
                </li>
                <li>
                    <article css={article}>
                        <div css={article__contents}>
                            <Typography css={[article__subtitle, no_margin]}>成分比較</Typography>
                            <Typography css={article__title} color='textPrimary'>1,000倍の総鉄イオン量。</Typography>
                            <Typography css={article__description}>
                                このエキスには、総鉄イオン量にして、療養泉の定義における特殊成分の<span css={article_primary_text}>約1,000倍濃度の温泉成分</span>が含まれています。含有されるミネラル成分は、微量なものも含め15種類以上。<br /><br />
                                大地から生まれた青粘土が、じんわりと浸透する温泉ミネラルへ。これがデイリーミネラルの成分の秘密なのです。
                            </Typography>
                        </div>
                        <ComponentsFigure css={components_figure} />
                    </article>
                </li>
                <li css={sp_hide}>
                    <article css={[article, flex_reverse]}>
                        <div css={article__contents}>
                            <Typography css={article__subtitle}>入り心地</Typography>
                            <Typography css={article__title} color='textPrimary'>湯疲れしないお湯の秘密。</Typography>
                            <Typography css={article__description}>
                                デイリーミネラルが目指したのは、本物の温泉基準さながらに全身トリートメントとして毎日でも使える入浴剤。<br /><br />
                                その成分はとてもシンプル。別府の大地から析出する、たっぷりのミネラルが詰まった独自成分「湯の花エキス」と、「ホウ酸」「セスキ炭酸ナトリウム」などの温泉成分から生まれています。
                            </Typography>
                        </div>
                        <BackgroundImage css={article__thumbnail} fluid={sbn_02.childImageSharp.fluid} />
                    </article>
                </li>
                <li css={sp_hide}>
                    <article css={article}>
                        <div css={article__contents}>
                            <Typography css={article__description}>
                                「湯の花エキス」は酸性で少し刺激性のある成分です。そのままだと、肌荒れや浴槽を傷める心配があります。そこで豊富なミネラルを活かしつつ、優しい湯質にするために、なめらかなアルカリ性成分と掛け合わせます。<br /><br />
                                酸性とアルカリ性は本来相反するもの。2つの成分をかけ合わせることにより激しい化学反応が起きますが、この反応に対して「加熱」と「冷却」を繰り返すことで、自然な状態をコントロールし、ゆっくり時間をかけ一体化を行っています。<br /><br />
                                この一体化に掛かる時間は約20時間以上。科学と自然の力で熟成させて作る入浴剤は、まろやかなpH9.6の弱アルカリ性になります。「ミネラル成分を閉じ込めながら、湯疲れしにくく、身体にしっとりと浸透する」。そんな理想の湯心地を実現しました。
                            </Typography>
                        </div>
                        <EffectsFigure css={components_figure} />
                    </article>
                </li>
                <li>
                    <article css={[article, flex_reverse]}>
                        <div css={article__contents}>
                            <Typography css={[article__description, no_margin]}>
                                疲れを和らげるための入浴で、逆に疲れてしまった覚えがある方もいらっしゃるのではないでしょうか。<br /><br />
                                デイリーミネラルをお風呂に入れることで、温泉成分が溶け出し、水道水に比べまろやかな入り心地になります。再現されるのはpH9.6の弱アルカリ湯質。「美肌の湯」と言われる湯に似た、クセがなく多くの方に好まれる湯質です。お湯はツルツル、湯上りしっとり、中ぽかぽか。有効成分のミネラル皮膜が身体を包み、湯冷めを防ぎつつ芯から温めてくれます。これが、独自の爽快感そして湯上がり感をもたらしてくれるのです。<br /><br />
                                成分としても入り心地としても、<span css={article_primary_text}>本物の温泉基準を彷彿させる</span>、まさに家庭用の温泉タイプ入浴剤なのです。
                            </Typography>
                        </div>
                        <Card css={components_figure} />
                    </article>
                </li>
            </ul>
        </Container>
    )
}

export default DailymineralHeader