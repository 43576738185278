import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const sp_hide = css`
	display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
	}
`

export const pc_hide = css`
	display: block;
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const product_top = css`
	padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-bottom: 66px;
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 60px;
	}
`

export const pc_slider = css`
	display: none;
	@media (min-width: ${breakpoints.tablet}px) {
		flex: 1;
		display: block;
	}
`

export const sm_slider = css`
	display: block;
	margin-left: -20px;
	margin-right: -20px;

	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const product_catch = css`
	margin-top: 20px;
	font-weight: normal;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
	}
`

export const product_title = css`
	margin-top: 10px;
`

export const product_price = css`
	margin-top: 10px;
	color: ${palette.primary.dark};
	font-size: 2rem;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}

	span {
		font-weight: normal;
		font-size: 1.6rem;
		color: ${palette.text.secondary};
	}
`

export const product_info = css`
	margin-top: 30px;
	padding: 12px 14px;
	background-color: ${palette.grey['500']};
	border-radius: 8px;

	p {
		font-weight: 500;
	}
`

export const link_btn = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		max-width: 335px;
		margin-top: 33px;
	}
`