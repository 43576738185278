import React from 'react'
import { Container } from '~/components/layout/Container'
import Typography from '~/components/atoms/Typography'
import { FacilityCard } from '~/components/organisms/OurCustomer/FacilityCard'
import { cards, container, facilities, horizon, pc_hide, prefecture, subtitle, title } from './styles'
import Horizon from '~/components/atoms/Horizon'

const OurCustomer = (): JSX.Element => {
    return (
        <Container css={container}>
            <Typography css={subtitle} color='textSecondary'>Our Customer</Typography>
            <Typography css={title} color='textPrimary'>
                業務用サイズ導入の温浴施設一覧
            </Typography>

            <ul css={facilities}>
                <li>
                    <div>
                        <Typography css={prefecture}>大阪府</Typography>
                        <Horizon css={horizon} />
                    </div>
                    <ul css={cards}>
                        <li>
                            <FacilityCard
                                name={'みやの湯'}
                                address={'〒571-0062 大阪府門真市宮野町13-10'}
                                phone={'TEL 070-881-3277'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'天水湯'}
                                address={'〒557-0014 大阪府大阪市西成区天下茶屋3丁目18-27'}
                                phone={'TEL 06-6651-1646'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'姫松温泉'}
                                address={'〒559-0013 大阪市住之江区御崎2-5-1'}
                                phone={'TEL 06‐6681‐0567'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'新柏原温泉'}
                                address={'〒582-0009 大阪府柏原市大正1-3-21'}
                                phone={'TEL 0729-72-3458'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'日の本湯'}
                                address={'〒570-0006 大阪府守口市八雲西町４丁目２０−１'}
                                phone={'TEL 06-6992-7652'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'源気温泉おゆば'}
                                address={'〒565-0826 大阪府吹田市千里万博公園11-11'}
                                phone={'TEL 06-6878-6878'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'朝日温泉'}
                                address={'〒558-0041 大阪府大阪市住吉区南住吉3丁目11-8'}
                                phone={'TEL 06‐6692‐9808'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'平和温泉'}
                                address={'〒563-0032 大阪府池田市石橋1-1-5'}
                                phone={'TEL 072-761-8196'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'巣本温泉'}
                                address={'〒571-0001 大阪府門真市巣本町5-20'}
                                phone={'TEL 072-882-5301'}
                                tags={[]}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    <div>
                        <Typography css={prefecture}>京都府</Typography>
                        <Horizon css={horizon} />
                    </div>
                    <ul css={cards}>
                        <li>
                            <FacilityCard
                                name={'むらさき湯'}
                                address={'〒603-8164 京都府京都市北区紫野東御所田町15'}
                                phone={'TEL 075-431-6558'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'玉の湯'}
                                address={'〒604-0941 京都府京都市中京区小路通御幸町西入ル亀屋町401'}
                                phone={'TEL 075-231-2985'}
                                tags={[]}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    <div>
                        <Typography css={prefecture}>東京都</Typography>
                        <Horizon css={horizon} />
                    </div>
                    <ul css={cards}>
                        <li>
                            <FacilityCard
                                name={'千代の湯'}
                                address={'〒116-0011 東京都荒川区西尾久5-22-14'}
                                phone={'TEL 03-3893-5439'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'梅の湯'}
                                address={'〒116-0011 東京都荒川区西尾久4-13-2'}
                                phone={'TEL 03-3893-1695'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'天狗湯'}
                                address={'〒167-0053 東京都杉並区西荻南１丁目２１−４'}
                                phone={'TEL 03-3333-9461'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'松本湯'}
                                address={'〒164-0003 東京都中野区東中野5-29-12'}
                                phone={'TEL 03-3371-8392'}
                                tags={[]}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    <div>
                        <Typography css={prefecture}>愛知県</Typography>
                        <Horizon css={horizon} />
                    </div>
                    <ul css={cards}>
                        <li>
                            <FacilityCard
                                name={'へいでん温泉'}
                                address={'〒461-0012 愛知県名古屋市東区相生町38'}
                                phone={'TEL 052-931-4009'}
                                tags={[]}
                            />
                        </li>
                        <li>
                            <FacilityCard
                                name={'白山温泉'}
                                address={'〒451-0053 愛知県名古屋市西区枇杷島1-2-20'}
                                phone={'TEL 052-531-4753'}
                                tags={[]}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    <div>
                        <Typography css={prefecture}>兵庫県</Typography>
                        <Horizon css={horizon} />
                    </div>
                    <ul css={cards}>
                        <li>
                            <FacilityCard
                                name={'ゆ〜もあらんど福栄'}
                                address={'〒660-0822 兵庫県尼崎市杭瀬南新町1丁目2-1'}
                                phone={'TEL 06-6489-3986'}
                                tags={[]}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    <div>
                        <Typography css={prefecture}>富山県</Typography>
                        <Horizon css={horizon} />
                    </div>
                    <ul css={cards}>
                        <li>
                            <FacilityCard
                                name={'立山鉱泉'}
                                address={'〒930-0801 富山市中島3丁目8-33'}
                                phone={'TEL 090-6816-6970'}
                                tags={[]}
                            />
                        </li>
                    </ul>
                </li>
            </ul>
        </Container>
    )
}

export default OurCustomer